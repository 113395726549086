import React from 'react'
import { graphql } from 'gatsby'
import TradePage from './index'

const TradeMaterials = ({ data }) => {
  return <TradePage data={data} />
}

export default TradeMaterials

export const query = graphql`
  query TradeMaterialsPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "trade-materials" }
      node_locale: { eq: $locale }
    ) {
      name
      noindex
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
