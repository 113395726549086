import React from 'react'
import { Box } from 'theme-ui'
import TradeFooter from '~/components/Footer/Trade'
import TradeNav from '~/components/Nav/Trade'

const TradeLayout = ({ children }) => {
  return (
    <Box>
      <Box
        as="header"
        role="banner"
        sx={{ position: 'sticky', top: '0', zIndex: '100' }}
      >
        <TradeNav />
      </Box>
      <Box as="main">{children}</Box>
      <TradeFooter />
    </Box>
  )
}

export default TradeLayout
