/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const TradeFooterContact = () => {
  const translate = useTranslate()

  return (
    <Box>
      <Text as="h2" variant="title5" mb={2}>
        {translate('footer.contact.title')}
      </Text>
      <Text as="p" variant="body3" sx={{ a: { variant: 'text.footerLink' } }}>
        <a
          href="mailto:trade@makerwine.com"
          sx={{ display: 'inline-block', py: [1, '4px'] }}
        >
          trade@makerwine.com
        </a>
      </Text>
    </Box>
  )
}

export default TradeFooterContact
