/** @jsx jsx */
import { Box, Container, Grid, jsx } from 'theme-ui'
import FooterLinksSection from '~/components/Footer/Links/Section'
import TradeFooterLegal from '~/components/Footer/Legal/Trade'
import TradeFooterContact from '~/components/Footer/Contact/Trade'
import useFooterQuery from '~/hooks/graphql/queries/use-footer'

export const TradeFooter = () => {
  const { trade } = useFooterQuery()

  return (
    <Box as="footer">
      <Box
        bg="brandPrimary"
        color="white"
        sx={{ pt: [6, 7, null, 9], pb: [7, null, null, 9] }}
      >
        <Container>
          <Grid columns={[1, 2]} sx={{ rowGap: '0' }}>
            <Box>
              {trade.pages.map(({ title, pages }, index) => (
                <FooterLinksSection
                  title={title}
                  pages={pages}
                  key={`trade-footer-section-${index}`}
                />
              ))}
            </Box>
            <Box>
              <TradeFooterContact />
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box
        bg="brandPrimaryDark"
        sx={{
          pb: [6, 4, 5],
          pt: [5, 4, 5],
        }}
      >
        <Container>
          <TradeFooterLegal />
        </Container>
      </Box>
    </Box>
  )
}

export default TradeFooter
