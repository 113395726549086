import React from 'react'
import NavBar from '~/components/Nav/Bar'
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation'

export const TradeNav = (props) => {
  const { trade } = useNavigationQuery()

  return <NavBar pages={trade.pages} isTrade={true} {...props} />
}

export default TradeNav
