/** @jsx jsx */
import { Link } from 'gatsby'
import { Flex, Text, jsx } from 'theme-ui'

const TradeFooterLegal = ({ links }) => {
  return (
    <Flex
      color="white"
      sx={{
        alignItems: 'center',
        flexDirection: ['column', 'row'],
        fontSize: '16px',
        justifyContent: ['center', 'space-between'],
        lineHeight: '22px',
        textAlign: ['center', 'left'],
      }}
    >
      <Text as="p" mr={[0, 3]} sx={{ flexShrink: '0', order: [1, 0] }}>
        {`Copyright © ${new Date().getFullYear()} Maker Wine Company`}
      </Text>
      <Text
        as="p"
        variant="body1"
        mb={[3, 0]}
        sx={{ a: { color: 'white', fontWeight: 'bold' } }}
      >
        <Link to="/">{'Return to makerwine.com'}</Link>
      </Text>
    </Flex>
  )
}

export default TradeFooterLegal
