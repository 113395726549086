import React from 'react'
import { graphql } from 'gatsby'
import ContentPage from '~/components/Content/Page'
import Metadata from '~/components/Metadata'
import TradeLayout from '~/components/Layout/Trade'

const Trade = ({ data }) => {
  const { page } = data

  return (
    <TradeLayout>
      <Metadata noindex={page.noindex} />
      <ContentPage page={page} isTrade={true} />
    </TradeLayout>
  )
}

export default Trade

export const query = graphql`
  query TradePage($locale: String) {
    page: contentfulPage(
      slug: { eq: "trade-landing" }
      node_locale: { eq: $locale }
    ) {
      name
      noindex
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
